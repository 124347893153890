import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'pdf-export-modal',
  templateUrl: './pdf-export-modal.component.html',
  styleUrls: ['./pdf-export-modal.component.scss'],
})
export class PdfExportModalComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) {}

  @Input() cardIds: string[];

  cards: ProfileCard[] = [
    {
      name: 'Bio',
      id: 'bio',
      default: true,
      checked: true,
      note: '(contains Name, Follower Count, Location, Bio status and relevant interests)',
    },
    { name: 'Audience age distribution', id: 'audience-age-distribution' },
    { name: 'Audience gender distribution', id: 'audience-gender-distribution' },
    { name: 'Audience location distribution', id: 'audience-location-distribution' },
    {
      name: 'Engagement',
      id: 'engagement',
      note: '(contains est.reach, est.impressions, total engagement and suspicious followers)',
    },
    { name: 'Follower Timeline', id: 'follower-timeline' },
    { name: 'Engagement Timeline', id: 'engagement-timeline' },
    { name: 'Branded Content distribution', id: 'branded-content-distribution' },
    { name: 'Audience interest distribution', id: 'audience-interest-distribution' },
  ];

  reportCards: ProfileCard[] = [
    {
      name: 'Stats',
      id: 'stats',
      default: true,
      checked: true,
      note: '', //(contains INFLUENCERS, POSTS , LIKES, COMMENTS ...)
    },
    {
      name: 'Audience Analysis',
      id: 'audience-analysis',
      default: true,
      checked: true,
      note: '', //(Campaign Reach stats)
    },
    {
      name: 'Comment Analysis',
      id: 'comment-analysis',
      default: true,
      checked: true,
      note: '', //(Comment Analysis)
    },
  ];

  @Input() maxSelectedItems = 10;
  @Input() selectedPosts: {
    id: string;
    disabled: boolean;
    username: string;
    urlMedium: string;
    comments: number;
    likes: number;
    caption: string;
  }[] = [];
  @Input() posts: {
    id: string;
    disabled: boolean;
    username: string;
    urlMedium: string;
    comments: number;
    likes: number;
    caption: string;
  }[];
  @Input() documentType: string;
  @Input() isExportAllPostsEnabled?: boolean;

  ngOnInit(): void {
    if (this.documentType === 'report') {
      this.cards = this.reportCards;
    } else {
      this.cards = this.cards.filter((card) => this.cardIds.includes(card.id));
    }
  }

  get isExportAllEnabled(): boolean {
    return this.documentType === 'report' && !!this.isExportAllPostsEnabled;
  }

  closeModal(): void {
    this.activeModal.close();
  }

  exportAllPostsAsPDF(): void {
    this.activeModal.close(this.posts?.map((post) => post.id));
  }

  exportPDF(): void {
    if (this.documentType === 'report') {
      this.activeModal.close(this.selectedPosts);
    } else {
      const cardstoExport = this.cards.filter((card) => card.checked).map((card) => card.id);
      this.activeModal.close(cardstoExport);
    }
  }
}

class ProfileCard {
  name: string;
  id: string;
  note?: string;
  checked?: boolean; // used in checkbox
  default?: boolean; // set to true if card will be added by default to exported pdf
}
