<div class="modal-header">
    <i class="fa fa-file-pdf-o"></i>
    <span>Export as PDF</span>
    <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="title">
        What information do you wish to download ?
    </div>
    <div class="card-checkbox" *ngFor="let card of cards">
        <nb-checkbox [(ngModel)]="card.checked" [disabled]="card.default">{{card.name}}
        </nb-checkbox>
        <span class="note" *ngIf="card.note">{{card.note}}</span>
    </div>
  <ng-template [ngIf]="documentType === 'report'">
    <div class="title">
      Posts
    </div>
    <ng-select [multiple]="true" [(ngModel)]="selectedPosts" [items]="posts" [maxSelectedItems]="maxSelectedItems"  bindLabel="username"
               bindValue="id" placeholder="Select Posts to export !">
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span class="ng-value-label ng-label" >{{item.username}}</span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <div class="ng-tmp">
          <img [lazyLoad]="item.urlMedium | thumbnail: 1080 | async"  defaultImage="/assets/images/print/post.svg" style="object-fit: cover" width="25px" height="50px" alt="">
          <div class="border-decorator ng-tmp-body" >
            <span  >{{item.username}}</span>
            <div class="ng-tmp-bottom">
                <p class="stats"> <b>comments: </b> {{item.comments}} |  <b>likes: </b>  {{item.likes}} </p>
                <p class="caption"> <b> <i class="fa fa-quote-left" ></i>  </b>  {{item.caption}}</p>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-select>
    <label class="footnote">
      <b>Note:</b> You can select a maximum of {{maxSelectedItems}} posts
    </label>
  </ng-template>
</div>
<div class="modal-footer">
    <button *ngIf="isExportAllEnabled" class="btn btn-xs btn-secondary" (click)="exportAllPostsAsPDF()">Export All Posts</button>
    <button class="btn btn-xs btn-primary" (click)="exportPDF()">Export Selected Posts</button>
</div>
